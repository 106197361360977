import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Session, User } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import PaymentRequestImage from '../../assets/payment_request.png'
import BankBalanceImage from '../../assets/balance.png'
import { app_version, clearCache } from 'service/Utils';

export const Dashboard = () => {
    const userData = useSelector((state: RootState) => state.appdata);
    const [session, setSession] = useState<Session>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (userData.profile) {
            setSession(userData.profile.profile);
        }
    }, [userData]);

    useEffect(() => {
        if (session != null) {
            setIsLoading(false);
        }
    }, [session]);

    return (
        <>
            <div className="page-dashboard">
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        <div className='page-header bg-dark' style={{ paddingBottom: "20px", marginBottom: "20px" }}>
                            Welcome,
                            <h2>{session?.user.name}</h2>
                        </div>
                        <div className='row'>
                            {session?.activities && session?.activities.indexOf("view_bank_accounts") > -1 &&
                                <div className="col-lg-3 col-md-6 col-6 mb-4">
                                    <NavLink to="/bankAccounts">
                                        <div className="card">
                                            <img className="card-img-top" src={BankBalanceImage} alt="Card image cap"></img>
                                            <div className="card-body">
                                                <h5 className="card-title">Bank Balances</h5>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            }
                            {session?.activities && session?.activities.indexOf("approve_paymentRequest") > -1 &&
                                <div className="col-lg-3 col-md-6 col-6 mb-4">
                                    <NavLink to="/paymentRequest">
                                        <div className="card">
                                            <img className="card-img-top" src={PaymentRequestImage} alt="Card image cap"></img>
                                            <div className="card-body">
                                                <h5 className="card-title">Payment Requests</h5>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            }
                        </div>
                        <div className='row'>
                            <div className="col-12 mb-4">
                                <div style={{ position: "relative", height: 50 }}>
                                    <div className='version-number'>
                                        <div className="btn btn-sm bg-dark" style={{ marginBottom: 10, color: "#fff", float: "left" }} onClick={clearCache}>Update to latest version</div>
                                        <span style={{ float: "right" }} >{app_version}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};