import React, { useState, useEffect } from 'react';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faBook, faListCheck, faChartLine, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Loader } from '../Widgets';
import { PaymentRequest } from 'service';
import moment from 'moment';

export const PaymentRequestPage = () => {
    const appdata = useSelector((state: RootState) => state.appdata);
    const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD"));
    const [status, setStatus] = useState<Number>(0);
    const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([]);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [screenName, setScreenName] = useState<string>("main-screen");
    const navigate = useNavigate();


    useEffect(() => {
        if (!date) {
            setDate(moment().format("YYYY-MM-DD"));
        }
    }, [appdata]);

    useEffect(() => {
        reloadData();
    }, [date, status]);

    const reloadData = () => {
        localStorage.removeItem("payment_requests");
        loadData();
    }

    const loadData = () => {
        setIsLoading(true);
        ArthaGateway
            .getPaymentRequests(date, status)
            .then((data) => {
                data.forEach((request) => { request.isExpanded = false; });
                setPaymentRequests(data);
                localStorage.setItem("payment_requests", JSON.stringify(data.entries));
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const toggleExapnd = (target) => {
        paymentRequests.map((request) => {
            if (request.id === target.id) {
                request.isExpanded = !request.isExpanded;
            }
            else {
                request.isExpanded = false;
            }
        });
        setPaymentRequests([...paymentRequests]);
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    const persistSessionData = (request?: PaymentRequest) => {
        if (request) {
            localStorage.setItem("request", JSON.stringify(request));
        }
        else {
            localStorage.removeItem("request");
        }
        localStorage.setItem("payment_requests", JSON.stringify(paymentRequests));
    }

    const showLedger = (request: PaymentRequest) => {
        persistSessionData(request);
        navigate("/ledger/" + request?.id);
    }

    const showBusinessSummary = (request: PaymentRequest) => {
        persistSessionData(request);
        navigate("/businessSummary/" + request?.id);
    }

    const showOutstanding = (request: PaymentRequest) => {
        persistSessionData(request);
        navigate("/outstanding/" + request?.id);
    }

    const setApprovedAmount = (amount: number) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, approvedAmount: amount });
        }
    }

    const showApproveScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request, approvedAmount: (request.requestedAmount + request.advanceAmount) });
        setScreenName("approve-screen");
    }

    const showPostponeScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request, approvedAmount: 0, postponedTo: moment(request.date).format("YYYY-MM-DD") });
        setScreenName("postpone-screen");
    }

    const showCancelScreen = (request: PaymentRequest) => {
        setPaymentRequest({ ...request });
        setScreenName("cancel-screen");
    }

    const cancelPaymentScreen = () => {
        setScreenName("main-screen");
    }

    const approvePayment = () => {
        if (paymentRequest) {
            paymentRequest.status = "Approved";
            paymentRequest.approvedAt = moment().format("YYYY-MM-DD HH:mm:ss");
            paymentRequest.approvedBy = appdata.profile?.profile?.user.id;
            ArthaGateway
                .updatePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const cancelPayment = () => {
        if (paymentRequest) {
            paymentRequest.status = "Cancelled";
            paymentRequest.cancelledAt = moment().format("YYYY-MM-DD HH:mm:ss");
            paymentRequest.cancelledBy = appdata.profile?.profile?.user.id;
            paymentRequest.approvedAmount = 0;
            ArthaGateway
                .updatePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const postponePayment = () => {
        if (paymentRequest) {
            paymentRequest.postponedBy = appdata.profile?.profile?.user.id;
            ArthaGateway
                .postponePaymentRequest(paymentRequest)
                .then((data) => {
                    setScreenName("main-screen");
                    loadData();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const setApproveDate = (date: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, date: date });
        }
    }

    const setPostponedDate = (date: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, postponedTo: date });
        }
    }

    const setComment = (comment: string) => {
        if (paymentRequest) {
            setPaymentRequest({ ...paymentRequest, comment: comment });
        }
    }

    return (
        <div className='page-party-list'>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <>
                    {screenName === "main-screen" &&
                        <>
                            <form>
                                <div className="mb-3">
                                    <input type="date" className="form-control" id="date" value={date} onChange={(e) => setDate(e.target.value)} disabled />
                                </div>
                                <div className="mb-3">
                                    <div className="btn btn-dark btn-sm" onClick={reloadData} style={{ width: "100%" }}>
                                        <FontAwesomeIcon icon={faRefresh} style={{ marginRight: 10 }} />
                                        Reload data
                                    </div>
                                </div>
                            </form>
                            <div className="accordion" id="requestAccordion">
                                {(!paymentRequests || paymentRequests.length == 0) &&
                                    <div className='alert alert-danger w-100'>No requests raised for the day</div>
                                }
                                {paymentRequests && paymentRequests.map((request) => (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={request.id.toString()} onClick={() => toggleExapnd(request)}>
                                            <div className={request?.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                                <div>
                                                    <div>{request.party.name}</div>
                                                    <div>
                                                        <div style={{ marginRight: "0.5rem", fontSize: "1.2rem", fontWeight: "bolder", float: "left" }}>{currencyFormat((request.requestedAmount + request.advanceAmount))}</div>
                                                        {request.status === "Requested" &&
                                                            <div className="badge bg-primary badge-sm">{request.status}</div>
                                                        }
                                                        {request.status === "Approved" &&
                                                            <div className="badge bg-success badge-sm">{request.status}</div>
                                                        }
                                                        {request.status === "Cancelled" &&
                                                            <div className="badge bg-danger badge-sm">{request.status}</div>
                                                        }
                                                        {request.status === "Postponed" &&
                                                            <div className="badge bg-danger badge-sm">{request.status}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </h2>
                                        <div id={"d" + request.id.toString()} className={request.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + request.id.toString()}>
                                            <div className="accordion-body">
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">
                                                        Vendor
                                                        <div className="fw-bold">{request.party.name}</div>
                                                    </li>
                                                    <li className="list-group-item">
                                                        Group
                                                        <div className="fw-bold">{request.party.group}</div>
                                                    </li>
                                                    {request.party.accountNumber != null && request.party.accountNumber.length > 0 &&
                                                        <li className="list-group-item">
                                                            Bank
                                                            <div className="fw-bold">{request.party.accountNumber}</div>
                                                            <div className="fw-bold">{request.party.ifscCode}</div>
                                                            <div className="fw-bold">{request.party.bankName}</div>
                                                            <div className="fw-bold">{request.party.branch}</div>
                                                        </li>
                                                    }
                                                    {(request.party.accountNumber == null || request.party.accountNumber.length == 0) &&
                                                        <li className="list-group-item">
                                                            <div className="fw-bold">Bank details not available</div>
                                                        </li>
                                                    }
                                                    <li className="list-group-item">
                                                        Balance
                                                        <div className="fw-bold">{currencyFormat((request.party.balance))}</div>
                                                    </li>
                                                    {request.requestedAmount > 0 &&
                                                        <li className="list-group-item">
                                                            Requested Amount
                                                            <div className="fw-bold">{currencyFormat(request.requestedAmount)}</div>
                                                        </li>
                                                    }
                                                    {request.advanceAmount > 0 &&
                                                        <li className="list-group-item">
                                                            Advance Amount
                                                            <div className="fw-bold">{currencyFormat(request.advanceAmount)}</div>
                                                        </li>
                                                    }
                                                    <li className="list-group-item">
                                                        Status<br />
                                                        {request.status === "Requested" &&
                                                            <div className="badge bg-primary badge-lg">{request.status}</div>
                                                        }
                                                        {request.status === "Approved" &&
                                                            <div className="badge bg-success badge-lg">{request.status}</div>
                                                        }
                                                        {request.status === "Cancelled" &&
                                                            <div className="badge bg-danger badge-lg">{request.status}</div>
                                                        }
                                                        {request.status === "Postponed" &&
                                                            <div className="badge bg-danger badge-lg">{request.status}</div>
                                                        }
                                                    </li>
                                                    {request.status === "Approved" && request.approvedAmount > 0 &&
                                                        <>
                                                            <li className="list-group-item">
                                                                Approved Amount
                                                                <div className="fw-bold">{currencyFormat(request.approvedAmount)}</div>
                                                            </li>
                                                            <li className="list-group-item">
                                                                Approved by
                                                                <div className="fw-bold">{request.approvedByName}</div>
                                                            </li>
                                                        </>
                                                    }
                                                    {request.status === "Postponed" &&
                                                        <>
                                                            <li className="list-group-item">
                                                                Postponed to
                                                                <div className="fw-bold">{moment(request.postponedTo).format("DD/MM/YYYY")}</div>
                                                            </li>
                                                            <li className="list-group-item">
                                                                Postponed by
                                                                <div className="fw-bold">{request.postponedByName}</div>
                                                            </li>
                                                        </>
                                                    }
                                                    {request.status === "Cancelled" &&
                                                        <>
                                                            <li className="list-group-item">
                                                                Cancelled by
                                                                <div className="fw-bold">{request.cancelledByName}</div>
                                                            </li>
                                                        </>
                                                    }
                                                    <li className="list-group-item">
                                                        Notes
                                                        <div className="fw-bold" dangerouslySetInnerHTML={{ __html: request.notes }}></div>
                                                    </li>
                                                    {request.comment && request.comment.length > 0 &&
                                                        <li className="list-group-item">
                                                            Comment
                                                            <div className="fw-bold" dangerouslySetInnerHTML={{ __html: request.comment }}></div>
                                                        </li>
                                                    }
                                                </ul>
                                                <div className="btn-group d-flex" role="group">
                                                    {request.status === "Requested" &&
                                                        <>
                                                            <div className="btn btn-success w-100" title="Approve" onClick={() => showApproveScreen(request)}>
                                                                Approve
                                                            </div>
                                                            <div className="btn btn-primary w-100" title="Postpone" onClick={() => showPostponeScreen(request)}>
                                                                Postpone
                                                            </div>
                                                            <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => showCancelScreen(request)}>
                                                                Cancel
                                                            </div>
                                                        </>
                                                    }
                                                    {request.status === "Approved" &&
                                                        <>
                                                            <div className="btn btn-success w-100" title="Whatsapp" onClick={() => showApproveScreen(request)}>
                                                                Edit
                                                            </div>
                                                            <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => showCancelScreen(request)}>
                                                                Cancel
                                                            </div>
                                                        </>
                                                    }
                                                    {/*<div className="btn btn-danger w-100" title="Whatsapp" onClick={() => { }}>
                                                        Reject
                                                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                    {screenName === "approve-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            <li className="list-group-item">
                                Vendor Balance
                                <div className="fw-bold">{currencyFormat((paymentRequest?.party.balance))}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Approved Amount</label>
                                    <input type="input" className="form-control" value={paymentRequest?.approvedAmount} onChange={(e) => setApprovedAmount(parseInt(e.target.value))} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => cancelPaymentScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => approvePayment()}>
                                    Confirm
                                </div>
                            </div>
                        </ul>
                    }
                    {screenName === "cancel-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            <li className="list-group-item">
                                Vendor Balance
                                <div className="fw-bold">{currencyFormat((paymentRequest?.party.balance))}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Approved Amount</label>
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.approvedAmount)}</div>
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => cancelPaymentScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => cancelPayment()}>
                                    Confirm Cancel Payment
                                </div>
                            </div>
                        </ul>
                    }
                    {screenName === "postpone-screen" &&
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Vendor
                                <div className="fw-bold">{paymentRequest?.party.name}</div>
                            </li>
                            {paymentRequest?.requestedAmount != undefined && paymentRequest?.requestedAmount > 0 &&
                                <li className="list-group-item">
                                    Requested Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.requestedAmount)}</div>
                                </li>
                            }
                            {paymentRequest?.advanceAmount != undefined && paymentRequest?.advanceAmount > 0 &&
                                <li className="list-group-item">
                                    Advance Amount
                                    <div className="fw-bold">{currencyFormat(paymentRequest?.advanceAmount)}</div>
                                </li>
                            }
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Postpone to</label>
                                    <input type="date" className="form-control" value={paymentRequest?.postponedTo} onChange={(e) => setPostponedDate(e.target.value)} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-outline mb-4">
                                    <label className="form-label">Comment</label>
                                    <textarea rows={3} className="form-control" value={paymentRequest?.comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                </div>
                            </li>
                            <div className="btn-group d-flex" role="group">
                                <div className="btn btn-danger w-100" title="Whatsapp" onClick={() => cancelPaymentScreen()}>
                                    Cancel
                                </div>
                                <div className="btn btn-success w-100" title="Whatsapp" onClick={() => postponePayment()}>
                                    Confirm
                                </div>
                            </div>
                        </ul>
                    }
                </>
            }
        </div>
    );
};