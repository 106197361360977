import { Loader } from '../Widgets';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import { Dealer, PendingInvoices } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { RootState } from 'store/store';

export const BankAccounts = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [bankAccounts, setBankAccounts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const paymentAmountRef = useRef<null | HTMLInputElement>(null)

    useEffect(() => {
        if (user.profile) {
            load();
        }
    }, [user]);

    const load = () => {
        setIsLoading(true);
        ArthaGateway
            .getBankAccounts()
            .then((data) => {
                setBankAccounts(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    const toggleExapnd = (target) => {
        bankAccounts.map((request) => {
            if (request.id === target.id) {
                request.isExpanded = !request.isExpanded;
            }
            else {
                request.isExpanded = false;
            }
        });
        setBankAccounts([...bankAccounts]);
    }

    const currencyFormat = (value) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(value);

    return (
        <div className='page page-accounts'>
            {isLoading &&
                <Loader />
            }
            {!isLoading &&
                <div className="accordion" id="bankAccountAccordion">
                    {(!bankAccounts || bankAccounts.length == 0) &&
                        <div className='alert alert-danger w-100'>No bank information is available now, Please refresh again</div>
                    }
                    {bankAccounts && bankAccounts.map((bankAccount) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={bankAccount.id.toString()} onClick={() => toggleExapnd(bankAccount)}>
                                <div className={bankAccount?.isExpanded === true ? "accordion-button active bg-dark" : "accordion-button collapsed"}>
                                    <div>
                                        <div className="fw-bold">{bankAccount.account.name}</div>
                                        <div className={(bankAccount.balance > 0) ? "badge bg-success badge-lg m-auto" : "badge bg-danger badge-lg"}>
                                            {currencyFormat((bankAccount.balance))}
                                        </div>
                                    </div>
                                </div>
                            </h2>
                            <div id={"b" + bankAccount.id.toString()} className={bankAccount.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d" + bankAccount.id.toString()}>
                                <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            Account Number
                                            <div className="fw-bold">{bankAccount.account.accountNumber}</div>
                                        </li>
                                        <li className="list-group-item">
                                            IFSC Code
                                            <div className="fw-bold">{bankAccount.ifscCode}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Bank
                                            <div className="fw-bold">{bankAccount.name}</div>
                                        </li>
                                        <li className="list-group-item">
                                            Branch
                                            <div className="fw-bold">{bankAccount.branch}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};